import { template as template_9f69a4d305ce4acab2b141400ed7de77 } from "@ember/template-compiler";
const FKText = template_9f69a4d305ce4acab2b141400ed7de77(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
