import { template as template_fc270a7a510c438ea513926bf3c9fcbe } from "@ember/template-compiler";
const FKControlMenuContainer = template_fc270a7a510c438ea513926bf3c9fcbe(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
